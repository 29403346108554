import React from "react"
import PropTypes from "prop-types"
import ReactPlayer from "react-player"

// ---------------------------------------------------------

import SVG from "@components/svg"

// ---------------------------------------------------------

import { video, player, play_button } from "./styles.module.scss"

// ---------------------------------------------------------

const Video = (props) => {
  const { videoUrl, videoEmbedCode } = props

  if (videoEmbedCode) {
    const iframeSrc = videoEmbedCode.match(/src="([^"]+)"/)[1]
    return (
      <div className={video}>
        <iframe
          src={iframeSrc}
          title="Video Player"
          allow="autoplay; fullscreen; encrypted-media; picture-in-picture"
        />
      </div>
    )
  }
  const playButton = (
    <div className={play_button}>
      <SVG name="play" />
    </div>
  )

  return (
    <div className={video}>
      <ReactPlayer
        className={player}
        controls
        height="100%"
        light
        playIcon={playButton}
        playing
        url={videoUrl}
        width="100%"
      />
    </div>
  )
}

Video.propTypes = {
  /**
   * URL of the video.
   */
  videoUrl: PropTypes.string,
  videoEmbedCode: PropTypes.node
}

Video.defaultProps = {}

export default Video
