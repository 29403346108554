import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import * as styles from "./styles.module.scss"

import Image from "@components/image"
import Button from "@components/button"
import Card from "@components/card"
import Grid from "@layout/grid"
import Video from "@components/video/component"

const Jumbotron = (props) => {
  const { backgroundImage, body, buttons, cards, children, heading, theme, video } = props
  const classes = classNames(styles.jumbotron, {
    [styles[`${theme}`]]: theme,
    [styles.no_background]: theme === "no-background"
  })

  return (
    <div className={classes}>
      {backgroundImage && (
        <Image alt="" className={styles.jumbotron_background_image} src={backgroundImage} />
      )}

      <div className={styles.jumbotron_content}>
        <div>
          {heading && theme !== "no-background" && <h1>{heading}</h1>}
          {body && <div className={styles.body} dangerouslySetInnerHTML={{ __html: body }}></div>}

          {buttons && (
            <div className={styles.buttons}>
              {buttons.map((button, idx) => {
                return (
                  <Button key={idx} theme={button.theme} to={button.link}>
                    {button.label}
                  </Button>
                )
              })}
            </div>
          )}

          {children &&
            children.length &&
            children.map((child, idx) => {
              const childClasses = classNames({
                [styles.iframe_wrapper]: child.type === "iframe"
              })
              return (
                <div className={childClasses} key={idx}>
                  {child}
                </div>
              )
            })}
          {children && !children.length && children}

          {(video?.videoEmbedCode || video?.videoUrl) && (
            <div className={styles.video_wrapper}>
              <Video videoEmbedCode={video?.videoEmbedCode} videoUrl={video?.videoUrl} />
            </div>
          )}

          {cards && (
            <Grid items="3" hasChildren gutter="15" layout="3">
              {cards.map((card, idx) => {
                return (
                  <Card key={idx} theme="testimonials" greenBorder={true}>
                    <div
                      className={styles.card_wrapper}
                      dangerouslySetInnerHTML={{ __html: card.body }}
                    ></div>
                  </Card>
                )
              })}
            </Grid>
          )}
        </div>
      </div>
    </div>
  )
}

Jumbotron.propTypes = {
  /**
   * Specifies the image src and alt text for the background image
   */
  backgroundImage: PropTypes.object,

  body: PropTypes.string,
  buttons: PropTypes.array,
  cards: PropTypes.array,

  /**
   * Specifies specific children to be displayed under body and heading
   */
  children: PropTypes.node,

  /**
   * Specifies the heading
   */
  heading: PropTypes.string,

  /**
   * Specifies the theme styles
   */
  theme: PropTypes.string,

  video: PropTypes.string
}

Jumbotron.defaultProps = {}

export default Jumbotron
