import imageFixture from "../image/fixtures"

export default {
  props: {
    backgroundImage: imageFixture.header_hero.src,
    heading: "Upgrade your truck or van with custom equipment"
  },
  videoBlock: {
    backgroundImage: imageFixture.header_hero.src,
    heading: "",
    theme: "darker"
  }
}
